<template>
  <div class="workflow-page">
    <Button @click="showAddWorkflowDialog" class="btn-add-workflow">Add Workflow</Button>

    <DataTable :value="models" dataKey="id" selectionMode="single" @row-select="onRowSelect">
      <Column field="id" header="ID"></Column>
      <Column field="sensorData" header="Sensor Data" :body="sensorDataTemplate"></Column>
      <Column field="trigger" header="Trigger"></Column>
      <Column field="model" header="Model"></Column>
      <Column field="endpoint" header="Endpoint" :body="endpointTemplate"></Column>
      <Column field="management" header="Management">
        <template #body>
          <div style="display: flex; align-items: center;">
            <img
              src="@/assets/img/three-dots.svg"
              alt="Management Icon"
              style="width: 24px; height: 24px; margin-right: 8px;"
              @click="showMenu($event, rowData)"

            />
            <Menu v-if="activeMenuData" :model="menuItems" :popup=True :style="menuStyle"  ref="menuRef"
 />

          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog v-model:visible="isDialogVisible" header="Edit Workflow" :modal="true">
      <div class="p-fluid">
        <div v-for="(sensor, index) in newWorkflow.sensorData" :key="index" class="p-field p-d-flex p-ai-center p-mb-3">
          <label :for="'sensorData' + index" class="p-mr-2">Sensor Data {{ index + 1 }}:</label>
          <InputText :id="'sensorData' + index" v-model="newWorkflow.sensorData[index]" placeholder="Enter sensor data from controller" />

          <template v-if="index === 0">
            <label :for="'trigger' + index" class="p-mr-2 p-ml-3">Trigger:</label>
            <Dropdown v-model="newWorkflow.trigger" :options="triggerOptions" placeholder="Select trigger" class="p-mr-2"/>
            <InputText v-if="newWorkflow.trigger" v-model="newWorkflow.triggerValue" placeholder="Set trigger value" />
          </template>

          <Button icon="pi pi-minus" class="p-button-text p-ml-2" @click="removeSensorData(index)" />
        </div>

        <Button icon="pi pi-plus" class="p-button-text p-mt-2" @click="addSensorData" label="Add Sensor Data" />

        <div class="p-field p-mt-4">
          <label for="model">Model:</label>
          <Dropdown v-model="newWorkflow.model" :options="modelOptions" placeholder="Select model"/>
        </div>

        <div class="p-field p-mt-4">
          <label for="actionRequired">Action Required:</label>
          <MultiSelect v-model="newWorkflow.actionRequired" :options="actionOptions" placeholder="Select actions" @change="handleActionChange"/>
        </div>

        <div v-if="showEmailAction" class="p-field p-mt-4">
          <label for="contacts">Select Contacts:</label>
          <MultiSelect 
            v-model="selectedGroups" 
            :options="contactOptions" 
            optionLabel="group" 
            placeholder="Select contacts"
            @change="handleContactChange"
          >
            <template #item="slotProps">
              <div>
                <strong>{{ slotProps.option.group }}</strong>
                <ul>
                  <li v-for="email in slotProps.option.emails" :key="email">
                    {{ email }}
                  </li>
                </ul>
              </div>
            </template>
          </MultiSelect>
        </div>

        <div v-if="showBackCommandAction" class="p-field p-mt-4">
          <label for="command">Command to Send:</label>
          <InputText v-model="newWorkflow.command" placeholder="Enter command"/>
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button label="Cancel" icon="pi pi-times" @click="isDialogVisible = false" class="p-button-text" />
        <Button label="Save" icon="pi pi-check" @click="saveWorkflow" class="p-button-save" />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, h,nextTick } from 'vue';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import { getModels } from '../services/model.service';
import threeDotsIcon from '@/assets/img/three-dots.svg';
import Menu from 'primevue/menu';


const menuRef = ref(null);
const iconRef = ref(null);

const models = ref([
  {
    id: 1,
    sensorData: ['Temperature: 22°C', 'Humidity: 45%'],
    trigger: 'Above 20',
    model: 'Model A',
    endpoint: 'https://example.com/api/model-a',
    actionRequired: ['edit', 'authenticate'],
    contacts: ['contact1@example.com', 'contact2@example.com'],
    command: 'Start Process',
    management: { imageSrc: threeDotsIcon }
  },
  {
    id: 2,
    sensorData: ['Temperature: 18°C', 'Humidity: 60%'],
    trigger: 'Below 25',
    model: 'Model B',
    endpoint: 'https://example.com/api/model-b',
    actionRequired: ['delete'],
    contacts: ['contact3@example.com', 'contact4@example.com'],
    command: 'Stop Process',
    management: { imageSrc: threeDotsIcon }
  }
]);

const isDialogVisible = ref(false);
const newWorkflow = ref({
  id: null,
  sensorData: [''],
  trigger: '',
  triggerValue: '',
  model: '',
  actionRequired: [],
  contacts: [],
  command: '',
});

const triggerOptions = ref([
  'Above',
  'Below',
  'Between'
]);
const menuItems = ref([
  { label: 'Authentication', command: () => handleAuthentication() },
  { label: 'Action', command: () => handleAction() },
  { label: 'Delete', command: () => handleDelete() }
]);
const modelOptions = ref([]);
const actionOptions = ref([
  { label: 'Edit', value: 'edit' },
  { label: 'Authenticate', value: 'authenticate' },
  { label: 'Delete', value: 'delete' }
]);

const contactOptions = ref([
  {
    group: 'Group 1',
    emails: [
      'contact1@example.com',
      'contact2@example.com'
    ]
  },
  {
    group: 'Group 2',
    emails: [
      'contact3@example.com',
      'contact4@example.com'
    ]
  }
]);

const selectedGroups = ref([]);
const showEmailAction = ref(false);
const showBackCommandAction = ref(false);

onMounted(async () => {
  try {
    const fetchedModels = await getModels();
    modelOptions.value = fetchedModels.map(model => ({ label: model.model, value: model.model }));
  } catch (error) {
    console.error('Error fetching models:', error);
  }
});

const showAddWorkflowDialog = () => {
  newWorkflow.value = {
    id: null,
    sensorData: [''],
    trigger: '',
    triggerValue: '',
    model: '',
    actionRequired: [],
    contacts: [],
    command: '',
  };
  isDialogVisible.value = true;
};

const onRowSelect = (event) => {
  const selectedWorkflow = event.data;
  newWorkflow.value = { ...selectedWorkflow };
  isDialogVisible.value = true;
};

const addSensorData = () => {
  newWorkflow.value.sensorData.push('');
};

const removeSensorData = (index) => {
  newWorkflow.value.sensorData.splice(index, 1);
};

const saveWorkflow = () => {
  if (newWorkflow.value.id) {
    const index = models.value.findIndex(model => model.id === newWorkflow.value.id);
    if (index !== -1) {
      models.value[index] = { ...newWorkflow.value, sensorData: newWorkflow.value.sensorData.map((sensor, index) => `Sensor ${index + 1}: ${sensor}`) };
    }
  } else {
    const newId = models.value.length ? Math.max(models.value.map(model => model.id)) + 1 : 1;
    models.value.push({ ...newWorkflow.value, id: newId, sensorData: newWorkflow.value.sensorData.map((sensor, index) => `Sensor ${index + 1}: ${sensor}`) });
  }
  isDialogVisible.value = false;
};

const handleActionChange = (values) => {
  showEmailAction.value = values.includes('authenticate');
  showBackCommandAction.value = values.includes('edit');
};

const handleContactChange = (values) => {
  newWorkflow.value.contacts = values;
};

const sensorDataTemplate = (rowData) => {
  return rowData.sensorData.map((data, index) => h('div', { key: index }, data));
};

const endpointTemplate = (rowData) => {
  return h('a', { href: rowData.endpoint, target: '_blank' }, rowData.endpoint);
};

const activeMenuData = ref(null);

const handleAuthentication = () => {
  console.log('Authentication action triggered');
};

const handleAction = () => {
  console.log('Action triggered');
};
const handleDelete = () => {
  console.log('Delete action triggered');
};
const showMenu = async (event, rowData) => {
  activeMenuData.value = activeMenuData.value === rowData ? null : rowData;
    event.stopPropagation(); // Prevent event from propagating

  // Ensure DOM has been rendered
  await nextTick();

  console.log('menuRef.value:', menuRef.value);  // Check what `menuRef.value` is
  const menuElement = menuRef.value ? menuRef.value.$el || menuRef.value : null;
  console.log('menu element', menuElement);  // Log the actual element or proxy

  if (menuElement) {
    const icon = event.currentTarget;
    const rect = icon.getBoundingClientRect();
    console.log('icon position:', rect);

    menuElement.style.position = 'absolute';
    menuElement.style.top = `${rect.bottom + window.scrollY}px`;
    menuElement.style.left = `${rect.left + window.scrollX}px`;
  } else {
    console.error('Menu element is not rendered or menuRef is incorrect.');
  }
};
const menuStyle = ref({
  position: 'absolute'
});
const handleClickOutside = (event) => {
  if (menuRef.value && !menuRef.value.contains(event.target) && !iconRef.value.contains(event.target)) {
    activeMenuData.value = null;  // Hide the menu if click is outside
  }
};

onMounted(() => {
  document.addEventListener('mousedown', handleClickOutside);
});
</script>

<style scoped>
.workflow-page {
  padding: 1rem;
}

.btn-add-workflow {
  margin-bottom: 1rem;
}

.p-dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.p-dialog-footer .p-button-save {
  margin-left: 0.5rem;
}

.p-field {
  margin-bottom: 1rem;
}
.p-menu {
  z-index: 1000; /* Ensure it appears above other elements */
}

</style>
